import api from '../helpers/api';
class AlbumService {

    

    // all school methods here
    async getAlbums(){
        return await api.get(`albums`,{
            params:{
                as_admin:true
            }
        }).then(
            response => {
                return response.data
            }
        )
    }
    async saveAlbum(formData){
        return await api.post(`albums`,formData).then(
            response => {
                return response.data
            }
        )
    }
    async updateAlbum(formData,id){
        console.log(formData);
        
        return await api.post(`albums/${id}`,formData).then(
            response => {
                return response.data
            }
        )
    }
    async deleteAlbum(id){
        
        return await api.delete(`albums/${id}`).then(
            response => {
                return response.data
            }
        )
    }

    async getImages(id){
        return await api.get(`albums/${id}`,{
            params:{
                as_admin:true
            }
        }).then(
            response => {
                return response.data
            }
        )
    }


    async uploadImages(formData,id) {
        return api.post(`albums/${id}/pictures`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        });
    }

    async deleteImage(imageId) {
        return api.delete(`/pictures/${imageId}`);
    }

}

export default new AlbumService;