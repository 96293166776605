import albumService from "../../services/album.service"


    export const state ={
        albums:[],

    }
    export const actions = {
        getAlbums({commit,}){
            return  albumService.getAlbums().then(
                data => {
                    commit ('SET_Albums', data.data);
                    return Promise.resolve(data.data);
                }, error => {
                    commit('AssetsFailed');
                    return Promise.reject(error);
                }
            );
        },

    }
    export const mutations = {
        SET_Albums(state, albums){
            state.albums = albums
        },
        AssetsFailed(){

        }
    }
    export const getters = {
        get_albums(state){
            return state.albums
        },
    }


